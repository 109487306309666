@font-face {
    font-family: 'Aller';
    src: url('../assets/fonts/subset-Aller-BoldItalic.eot');
    src: url('../assets/fonts/subset-Aller-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/subset-Aller-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/subset-Aller-BoldItalic.woff') format('woff'),
        url('../assets/fonts/subset-Aller-BoldItalic.ttf') format('truetype'),
        url('../assets/fonts/subset-Aller-BoldItalic.svg#Aller-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Aller';
    src: url('../assets/fonts/subset-Aller-Bold.eot');
    src: url('../assets/fonts/subset-Aller-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/subset-Aller-Bold.woff2') format('woff2'),
        url('../assets/fonts/subset-Aller-Bold.woff') format('woff'),
        url('../assets/fonts/subset-Aller-Bold.ttf') format('truetype'),
        url('../assets/fonts/subset-Aller-Bold.svg#Aller-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Aller';
    src: url('../assets/fonts/subset-Aller.eot');
    src: url('../assets/fonts/subset-Aller.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/subset-Aller.woff2') format('woff2'),
        url('../assets/fonts/subset-Aller.woff') format('woff'),
        url('../assets/fonts/subset-Aller.ttf') format('truetype'),
        url('../assets/fonts/subset-Aller.svg#Aller') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Aller';
    src: url('../assets/fonts/subset-Aller-LightItalic.eot');
    src: url('../assets/fonts/subset-Aller-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/subset-Aller-LightItalic.woff2') format('woff2'),
        url('../assets/fonts/subset-Aller-LightItalic.woff') format('woff'),
        url('../assets/fonts/subset-Aller-LightItalic.ttf') format('truetype'),
        url('../assets/fonts/subset-Aller-LightItalic.svg#Aller-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Aller';
    src: url('../assets/fonts/subset-Aller-Light.eot');
    src: url('../assets/fonts/subset-Aller-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/subset-Aller-Light.woff2') format('woff2'),
        url('../assets/fonts/subset-Aller-Light.woff') format('woff'),
        url('../assets/fonts/subset-Aller-Light.ttf') format('truetype'),
        url('../assets/fonts/subset-Aller-Light.svg#Aller-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Aller';
    src: url('../assets/fonts/subset-Aller-Italic.eot');
    src: url('../assets/fonts/subset-Aller-Italic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/subset-Aller-Italic.woff2') format('woff2'),
        url('../assets/fonts/subset-Aller-Italic.woff') format('woff'),
        url('../assets/fonts/subset-Aller-Italic.ttf') format('truetype'),
        url('../assets/fonts/subset-Aller-Italic.svg#Aller-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Aller Display';
    src: url('../assets/fonts/subset-AllerDisplay.eot');
    src: url('../assets/fonts/subset-AllerDisplay.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/subset-AllerDisplay.woff2') format('woff2'),
        url('../assets/fonts/subset-AllerDisplay.woff') format('woff'),
        url('../assets/fonts/subset-AllerDisplay.ttf') format('truetype'),
        url('../assets/fonts/subset-AllerDisplay.svg#AllerDisplay') format('svg');
    font-weight: normal;
    font-style: normal;
}