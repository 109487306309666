.about {
  &__text {
    color: $black;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 1px;
  }

  &__links {
    color: $red;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 1px;
    @media (max-width: 768px) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
}