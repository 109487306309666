.galerie {
  &__outer {
    margin-bottom: 30px;
  }

  &__image {
    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 30px;
    }
  }

  &__thumb {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 200px;
  }

  &__inside {
    box-shadow: 0 0 40px rgba(237, 28, 36, 0.75);
    background-color: $red;
    padding: 0;
    height: 100%;
    position: relative;
    @extend .easing;

    &:hover {
      box-shadow: 0 0 40px rgba(196, 22, 28, 0.75);
      background-color: $red--high;

      .galerie__link,
      .galerie__text {
        display: block;
      }

      .galerie__thumb {
        display: none;
      }
    }
  }

  &__text {
    opacity: 0.6;
    color: $white;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.16px;
    padding: 5px 22px 90px;
    display: none;
  }

  &__link {
    width: 100%;
    text-align: center;
    padding-top: 30px;
    position: absolute;
    bottom: 37px;
    left: 0;
    display: none;

    a {
      color: $white;
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      border: 1px solid $white;
      padding: 9px 32px;
      line-height: 39px;
      border-radius: 3px;
      @extend .easing;

      &:hover {
        background: $white;
        color: $red;
        text-decoration: none;
      }
    }
  }

  &__heading {
    color: $white;
    font-size: 23px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 0.25px;
    position: relative;
    margin-bottom: 10px;
    padding: 21px 22px 10px;

    &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $white;
      opacity: 0.2;
    }
  }

  .row > div:nth-child(odd) {
    .galerie__inside {
      box-shadow: 0 0 40px rgba(196, 22, 28, 0.75);
      background-color: $red--high;

      &:hover {
        box-shadow: 0 0 40px rgba(237, 28, 36, 0.75);
        background-color: $red;
      }
    }
  }
}