.contact {
  position: relative;
  padding-top: 0;

  .heading__h2 {
    margin: 60px 0 40px;
  }

  &__phone {
    color: $red;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 0.35px;
  }

  &__overmap {
    z-index: 2;
    position: relative;
  }

  #map {
    width: 52%;
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 0;
    height: 100%;
  }

  form {
    label {
      position: relative;
      margin-bottom: 40px;

      span.label {
        color: $black;
        font-size: 16px;
        font-weight: 300;
        line-height: 30px;
        text-transform: uppercase;
        letter-spacing: 0.16px;
        position: absolute;
        top: 0;
        left: 0;
        white-space: nowrap;

        &.focused {
          top: -20px;
          font-size: 13px;
        }
      }
    }

    input[type="text"],
    input[type="email"] {
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      color: $black;
      font-size: 16px;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0.16px;
      border-bottom: 1px $red solid;
      width: 100%;
      outline: none;
    }

    input[type="submit"] {
      color: $red--high;
      font-size: 15px;
      font-weight: 700;
      line-height: 30px;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 3px;
      padding: 10px 40px;
      border: 1px $red--high solid;
    }

    textarea {
      width: 100%;
      height: 200px;
      max-height: 200px;
      min-height: 200px;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      border-bottom: 1px $red solid;
    }
  }
}