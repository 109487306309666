.header {
  position: fixed;
  height: 132px;
  box-shadow: 0 3px 5px rgba(0, 0, 1, 0.13);
  background-color: $white;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  @media (max-width: 1400px) {
    height: 100px;
  }

  .wpml-ls-statics-shortcode_actions {
    display: inline-block;
    position: relative;
    background: #fff;
    padding: 0 20px 0 10px;
    margin-left: 10px;
    background: url("../assets/ico-arrow.png") top 13px right no-repeat;
    @media (max-width: 1100px) {
      display: none;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    &:hover {
      .wpml-ls-sub-menu {
        display: block;
      }
    }

    a {
      color: $red--high;
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
      text-transform: uppercase;
      letter-spacing: 0.17px;
    }

    .wpml-ls-sub-menu {
      position: absolute;
      padding: 0 10px;
      left: 0;
      top: 30px;
      display: none;
      background: #fff;
    }
  }

  &--open {
    height: 100%;

    .header__nav {
      display: block;
      top: 40px;
    }

    .wpml-ls-statics-shortcode_actions {
      display: block;
    }

    .header__search {
      position: absolute;
      top: 86px;
      left: 10px;
    }
  }

  &__logo {
    float: left;
    margin-left: 30px;
    margin-top: 33px;
    @media (max-width: 1400px) {
      margin-top: 29px;

      img {
        width: 180px;
      }
    }
  }

  #searchform {
    display: none;
    position: fixed;
    top: 132px;
    background: #fff;
    width: 100%;
    z-index: 3;
    border-top: 1px $red--high solid;
    padding: 30px 0;
    text-align: center;
    box-shadow: 0 3px 5px rgba(0, 0, 1, 0.13);
    @media (max-width: 1400px) {
      top: 100px;
    }

    label {
      color: #000000;
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
      padding: 5px 20px 5px 0;
      letter-spacing: 0.18px;
    }

    input[type="text"] {
      color: #000;
      font-size: 13px;
      font-weight: 700;
      cursor: pointer;
      line-height: 30px;
      letter-spacing: 1.5px;
      border: 1px solid #000;
      display: inline-block;
      padding: 2px 5px;
      border-radius: 3px;
      outline: none;
    }

    input[type="submit"] {
      color: $red--high;
      font-size: 15px;
      font-weight: 700;
      cursor: pointer;
      line-height: 30px;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      border: 1px solid $red--high;
      display: inline-block;
      padding: 2px 15px;
      outline: none;
      border-radius: 3px;

      &:hover {
        background: $red--high;
        color: #fff;
      }
    }
  }

  &__search {
    float: right;
    margin-right: 20px;

    &_ico {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url("../assets/ico-search.png");
      cursor: pointer;
      margin-top: 53px;
      @media (max-width: 1400px) {
        margin-top: 43px;
      }
      @media (max-width: 1100px) {
        display: none;
      }
    }
  }

  &__respo {
    float: right;
    right: 30px;
    top: 40px;
    position: absolute;
    margin-right: 0;
    z-index: 9;
    display: none;
    @media (max-width: 1100px) {
      display: block;
    }

    .text {
      color: #c4161c;
      font-size: 16px;
      font-weight: 400;
      line-height: 13px;
      position: relative;
      top: 5px;
      float: left;
      text-transform: uppercase;
      padding-right: 10px;
      cursor: pointer;
      @media (max-width: 450px) {
        display: none;
      }
    }

    .icon {
      width: 30px;
      height: 53px;
      position: relative;
      float: left;
      margin-right: 15px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        cursor: pointer;
        height: 4px;
        width: 100%;
        background: #c4161c;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }

      span:nth-child(1) {
        top: 0;
      }

      span:nth-child(2),
      span:nth-child(3) {
        top: 11px;
      }

      span:nth-child(4) {
        top: 22px;
      }

      &.open span:nth-child(1) {
        top: 11px;
        width: 0;
        left: 50%;
      }

      &.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &.open span:nth-child(4) {
        top: 18px;
        width: 0;
        left: 50%;
      }
    }
  }

  &__nav {
    float: left;
    padding: 0 40px 0 80px;
    position: relative;
    top: 35px;
    @media (max-width: 1100px) {
      display: none;
      width: 100%;
      top: 100px;
      background: #fff;
      padding: 0 30px;
    }
    @media (max-width: 1400px) {
      top: 22px;
    }

    ul {
      list-style: none;
      @media (max-width: 1400px) {
        padding: 0;
      }

      li {
        float: left;
        position: relative;
        @media (max-width: 1100px) {
          width: 100%;
        }

        &:hover .sub-menu {
          display: block;
        }

        .sub-menu {
          display: none;
          position: absolute;
          background: #fff;
          padding: 20px 0;

          li {
            a {
              padding: 5px 11px !important;
            }

            &.current_page_item,
            &:hover {
              &:after {
                display: none !important;
              }

              a {
                color: $red--high;
              }
            }
          }
        }

        &.current_page_item,
        &:hover {
          &:after {
            width: 50px;
            height: 1px;
            background-color: $red--high;
            left: 50%;
            position: absolute;
            bottom: -5px;
            content: "";
            margin-left: -25px;
            @media (max-width: 1100px) {
              left: 0;
              margin-left: 0;
              bottom: 5px;
            }
          }
        }

        &:nth-child(1) {
          a {
            padding-left: 0;
          }
        }

        a {
          color: $black;
          font-size: 16px;
          font-weight: 300;
          line-height: 30px;
          text-transform: uppercase;
          letter-spacing: 0.16px;
          padding: 0 22px;
          @media (max-width: 1600px) {
            font-size: 14px;
            padding: 0 11px;
          }
          @media (max-width: 1400px) {
            font-size: 12px;
          }
          @media (max-width: 1100px) {
            font-size: 18px;
            padding: 10px 0;
            display: inline-block;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}