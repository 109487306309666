.heading {
  &__h2 {
    color: $black;
    font-size: 35px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.35px;
    margin: 120px 0 80px;
    @media (max-width: 1100px) {
      margin: 30px 0;
    }

    &:after {
      width: 110px;
      height: 1px;
      background-color: $red--high;
      content: " ";
      position: absolute;
      left: 0;
      bottom: -15px;
    }

    &--reviews {
      color: $red--high;
      font-size: 206px;
      font-weight: 700;
      line-height: 41.27px;
      text-transform: uppercase;
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0;
      top: -120px;
      letter-spacing: 1.5px;
      @media (max-width: 991px) {
        font-size: 130px;
        top: -30px;
      }
      @media (max-width: 768px) {
        font-size: 60px;
        top: -40px;
      }

      &:after {
        display: none;
      }
    }

    &--heading_slider {
      opacity: 0.8;
      color: $white;
      font-size: 85px;
      font-weight: 700;
      line-height: 90px;
      text-transform: uppercase;
      letter-spacing: 0.85px;
      margin: 380px 0 0;
      display: inline-block;
      @media (max-width: 1199px) {
        font-size: 40px;
        line-height: 45px;
        margin-top: 120px;
      }

      &:after {
        background-color: $white;
        opacity: 0.8;
        width: 100%;
        bottom: -25px;
        @media (max-width: 1199px) {
          bottom: -10px;
        }
      }
    }
  }
}