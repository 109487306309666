.page {
  &_text {
    &__inside {
      padding-bottom: 40px;

      h1 {
        color: $black;
        font-size: 35px;
        font-weight: 700;
        line-height: 35px;
        text-transform: uppercase;
        letter-spacing: 0.35px;
        position: relative;
        margin: 120px 0 80px;

        &:after {
          width: 110px;
          height: 1px;
          background-color: $red--high;
          content: "";
          position: absolute;
          left: 0;
          bottom: -15px;
        }
      }

      h2 {
        color: $red--high;
        font-size: 25px;
        font-weight: 700;
        line-height: 30px;
        text-transform: uppercase;
        letter-spacing: 0.25px;
        padding: 30px 0 15px;
      }

      p {
        color: $black;
        font-size: 16px;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 1px;
      }

      ul {
        li {
          color: $black;
          font-size: 16px;
          font-weight: 300;
          line-height: 30px;
          letter-spacing: 1px;
        }
      }
    }
  }
}