.reviews {
  background-color: $red;
  overflow: hidden;
  position: relative;
  padding: 220px 0 80px;
  @media (max-width: 991px) {
    padding-top: 120px;
  }
  @media (max-width: 768px) {
    padding-top: 60px;
  }

  &__item {
    position: relative;

    &:before {
      color: $red--high;
      font-size: 150px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      content: "“";
      position: absolute;
      left: -40px;
      top: 0;
      font-family: Arial;
    }
  }

  &__name {
    color: $white;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 0.25px;
    margin-top: 35px;
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 1px rgba(255,255,255,.2) solid;
  }

  &__text {
    opacity: 0.6;
    color: $white;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.16px;
  }
}