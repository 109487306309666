.btn {
  &.btn-primary {
    color: $white;
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    border: 1px solid $red--high;
    background: $white;
    color: $red--high;
    line-height: 57px;
    padding: 0 25px;
    @extend .easing;

    &:hover {
      background: $red--high;
      color: $white;
    }
  }
}