* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 132px 0 0;
  background: #fff;
  color: #333;
  -webkit-font-smoothing: antialiased;
  font-family: 'Aller';
  padding-top: 132px;
  @media (max-width: 1400px) {
    padding-top: 90px;
  }

  &.home {
    padding-top: 0;
  }
}

.easing {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

.page__back {
  position: relative;
  top: -30px;
}