.galerie_vypis{
  &__item{
    a{
      width: 100%;
      height: 100%;      
      margin-bottom: 15px;
      overflow: hidden;
      max-height: 233px;
      float: left;
      img{
        max-width: 100%;
      }
    }
  }
  &__backlink{
    background: url(../assets/ico-arrow.svg) center no-repeat;
    width: 20px;
    height: 20px;
    position: relative;
    top: -25px;
    display: inline-block;
    margin-left: -5px;
    transform: rotate(-180deg);
  }
}