.heading_shortcut {
  position: relative;
  z-index: 2;
  top: -150px;
  margin-bottom: -150px;

  &__heading {
    color: $white;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 0.25px;
    padding-bottom: 8px;
    border-bottom: 1px rgba(255,255,255,.2) solid;
    width: 100%;
  }

  &__text {
    opacity: 0.6;
    color: $white;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.16px;
    padding-top: 30px;
  }

  &__inside {
    box-shadow: 0 0 40px rgba(196, 22, 28, 0.75);
    background-color: $red--high;
    padding: 33px 44px 80px;
    position: relative;
    @media (max-width: 1199px) {
      padding-bottom: 33px;
    }
    @media (max-width: 768px) {
      margin-bottom: 40px;
    }

    &--color {
      box-shadow: 0 0 40px rgba(237, 28, 36, 0.75);
      background-color: $red;
    }
  }

  &__link {
    position: absolute;
    right: 33px;
    bottom: 44px;
    @media (max-width: 1199px) {
      position: static;
      margin-top: 20px;
    }

    a {
      color: $white;
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      border: 1px solid $white;
      padding: 12px 45px;
      line-height: 39px;
      border-radius: 3px;
      @extend .easing;
      @media (max-width: 1199px) {
        width: 100%;
      }

      &:hover {
        background: $white;
        color: $red;
        text-decoration: none;
      }
    }
  }
}