.stavby {
  &__inside {
    box-shadow: 0 0 35px rgba(0, 0, 1, 0.1);
    background-color: $white;
    margin-bottom: 55px;
    padding-bottom: 25px;
  }

  &__heading {
    color: $black;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.18px;
    padding: 40px 30px 20px;
  }

  &__image {
    width: 100%;
    padding-bottom: 56.25%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__text {
    color: $black;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.16px;
    padding: 0 30px;
  }

  &__price {
    color: $red;
    font-size: 25px;
    font-weight: 900;
    padding: 0 30px;
  }

  &__link {
    width: 100%;
    text-align: center;
    padding-top: 30px;

    a {
      color: $red;
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      border: 1px solid $red;
      padding: 9px 32px;
      line-height: 39px;
      border-radius: 3px;
      @extend .easing;

      &:hover {
        background: $red;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}