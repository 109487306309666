.footer {
  padding: 60px 0;

  p {
    color: $red--high;
    font-size: 15px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.15px;
    text-align: center;
    width: 100%;
    display: inline-block;

    a {
      color: $red--high;
      text-decoration: underline;
    }
  }
}