.heading_slider {
  &__item {
    height: 800px;
    background-size: cover;
    background-position: center;
    text-align: center;
    @media (max-width: 1199px) {
      height: 400px;
    }
  }

  .slick-arrow {
    background: rgba(255,255,255,.8) url("../assets/ico-arrow.svg") center no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    position: absolute;
    top: 50%;
    margin-top: -26px;
    width: 52px;
    height: 52px;
    z-index: 9;
    text-indent: -99999px;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    @extend .easing;
    border-radius: 3px;

    &:hover {
      background-color: $white;
    }

    &.slick-next {
      right: 30px;
    }

    &.slick-prev {
      left: 30px;
      transform: rotate(-180deg);
    }
  }
}