.news {
  padding-bottom: 90px;

  &__slider {
    margin-top: -30px;
  }

  &__item {
    padding: 0;
    margin: 30px 0;
  }

  &__inside {
    box-shadow: 0 0 29px rgba(0, 0, 1, 0.1);
    background-color: $white;
    margin: 0 15px;
  }

  &__heading {
    color: $black;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    padding: 5px 30px;
    letter-spacing: 0.18px;
  }

  &__date {
    color: $red;
    font-size: 15px;
    font-weight: 300;
    line-height: 30px;
    padding: 5px 30px;
    letter-spacing: 0.15px;
  }

  &__text {
    padding: 5px 30px;
    color: $black;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.16px;
  }

  &__link {
    width: 100%;
    padding: 20px 0;
    text-align: center;

    a {
      color: $red;
      font-size: 15px;
      font-weight: 700;
      line-height: 30px;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      border: 1px solid $red;
      display: inline-block;
      @extend .easing;
      padding: 2px 35px;
      border-radius: 3px;

      &:hover {
        background: $red;
        color: $white;
        text-decoration: none;
      }
    }
  }

  &__image {
    width: 100%;
    height: 213px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .slick-arrow {
    background: $red--high url(../assets/ico-arrow-white.svg) center no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    position: absolute;
    top: -55px;
    margin-top: -26px;
    width: 52px;
    height: 52px;
    z-index: 9;
    text-indent: -99999px;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    @extend .easing;
    border-radius: 3px;
    @media (max-width: 1100px) {
      top: -15px;
    }

    &:hover {
      background-color: $red;
    }

    &.slick-next {
      right: 15px;
    }

    &.slick-prev {
      right: 77px;
      transform: rotate(-180deg);
    }
  }
}